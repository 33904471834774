import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Router } from '@reach/router';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, gql, useQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import moment from 'moment';
import fromMarkdown from 'mdast-util-from-markdown';
import toHast from 'mdast-util-to-hast';
import toHtml from 'hast-util-to-html';
import get from 'lodash/get';
import Page from '../templates/page';
import BlogPost from '../templates/blogPost';
import Layout from '../templates/landingPage';
import HomePage from '../pages/index';
import * as analytics from '../utils/analytics';
import { capitalize } from '../utils/string';

// map Contentful GraphQL api responses to Gatsby/Contentful plugin format

const ACCORDION_FRAGMENT = gql`
  fragment accordionFields on Accordion {
    sys {
      id
    }
    title
    body {
      json
      links {
        assets {
          block {
            sys {
              id
            }
            title
            url
          }
          hyperlink {
            sys {
              id
            }
            title
            url
          }
        }
        entries {
          block {
            __typename
            sys {
              id
            }
          }
          hyperlink {
            __typename
            sys {
              id
            }
          }
          inline {
            __typename
            sys {
              id
            }
          }
        }
      }
    }
  }
`;

const mapAccordion = (entry, refMap) =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulAccordion',
        body: entry.body
          ? {
              ...JSON.parse(JSON.stringify(entry.body)),
              __typename: 'ContentfulAccordionBody',
              raw: JSON.stringify(entry.body.json),
              links: undefined,
              references: refsForRichText(entry.body.links, refMap),
            }
          : undefined,
      }
    : undefined;

const ASSET_FRAGMENT = gql`
  fragment assetFields on Asset {
    sys {
      id
    }
    title
    description
    fileName
    contentType
    url
    size
    width
    height
  }
`;

const mapAsset = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulAsset',
        file: {
          url: entry.url,
          fileName: entry.fileName,
          contentType: entry.contentType,
          details: {
            image: {
              width: entry.width,
              height: entry.height,
            },
          },
        },
      }
    : undefined;

const BLOG_LINK_FRAGMENT = gql`
  fragment blogLinkFields on BlogPost {
    sys {
      id
    }
    slug
    postType
  }
`;

const mapBlogLink = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulBlogPost',
      }
    : undefined;

const mapComponent = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulComponent',
        body: entry.body
          ? {
              ...JSON.parse(JSON.stringify(entry.body)),
              __typename: 'ContentfulComponentBody',
              raw: JSON.stringify(entry.body.json),
            }
          : undefined,
        data: entry.data
          ? {
              internal: { content: JSON.stringify(entry.data) },
            }
          : undefined,
      }
    : undefined;

const CONTACT_FRAGMENT = gql`
  fragment contactFields on Contact {
    sys {
      id
    }
    name
    jobTitle
    email
    phoneNumber
    info
    image {
      ...assetFields
    }
  }
`;

const mapContact = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulContact',
        info: markdown(entry, 'info'),
        image: mapAsset(entry.image),
      }
    : undefined;

const PAGE_LINK_FRAGMENT = gql`
  fragment pageLinkFields on Page {
    sys {
      id
    }
    slug
    title
    parentPage {
      ... on Page {
        slug
        title
        parentPage {
          ... on Layout {
            slug
          }
        }
      }
      ... on Layout {
        slug
      }
    }
  }
`;

const SERVICE_POINT_MAP_FRAGMENT = gql`
  fragment servicePointMapFields on ServicePointMap {
    sys {
      id
    }
  }
`;

const CAROUSEL_FRAGMENT = gql`
  fragment carouselFields on Carousel {
    sys {
      id
    }
    title
    slug
    logoCarousel
    logoCarouselColor
    slidesCollection(limit: 4) {
      items {
        ... on LayoutLinkList {
          ...linkListFields
        }
      }
    }
  }
`;

const mapPageLink = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: entry.__typename === 'Page' ? 'ContentfulPage' : 'ContentfulLayout',
        parentPage: mapPageLink(entry.parentPage),
      }
    : null;

const mapServicePointMap = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulServicePointMap',
      }
    : undefined;

const mapCarousel = entry => {
  const obj = entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulCarousel',
        slides: entry.logoCarousel ? entry.slidesCollection.items.filter(it => !!it).map(mapLayoutLinkList) : [],
        slidesCollection: undefined,
      }
    : undefined;

  return obj;
};

const FAQ_FRAGMENT = gql`
  fragment faqFields on FrequentlyAskedQuestion {
    sys {
      id
    }
    question
    answer
  }
`;

const mapFaq = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulFrequentlyAskedQuestion',
        question: entry.question,
        answer: markdown(entry, 'answer'),
      }
    : undefined;

const FAQ_CATEGORY_FRAGMENT = gql`
  fragment faqCategoryFields on FrequentlyAskedQuestionCategory {
    sys {
      id
    }
    headline
    frequentlyAskedQuestionsCollection(limit: 10) {
      items {
        ...faqFields
      }
    }
  }
`;

const mapFaqCategory = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulFrequentlyAskedQuestionCategory',
        headline: entry.headline,
        frequentlyAskedQuestionsCollection: undefined,
        frequentlyAskedQuestions: entry.frequentlyAskedQuestionsCollection.items.filter(it => !!it).map(mapFaq),
      }
    : undefined;

const HERO_IMAGE_FRAGMENT = gql`
  fragment heroImageFields on LayoutHeroImage {
    sys {
      id
    }
    headline
    visualStyle
    backgroundImage {
      ...assetFields
    }
    backgroundColor
    accentColor
    callToAction {
      ...layoutLinkFields
    }
  }
`;

const mapHeroImage = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulLayoutHeroImage',
        backgroundImage: fluidImage(entry.backgroundImage),
        callToAction: mapLayoutLink(entry.callToAction),
      }
    : undefined;

const LAYOUT_HIGHLIGHT_FRAGMENT = gql`
  fragment highlightFields on LayoutHighlight {
    sys {
      id
    }
    headline
    copy
    ctaLink
    backgroundColor
    ctaTitle
    ctaPage {
      ...pageLinkFields
    }
    footer
    image {
      ...assetFields
    }
    image2 {
      ...assetFields
    }
    visualStyle
  }
`;

const mapLayoutHighlight = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulLayoutHighlight',
        ctaPage: mapPageLink(entry.ctaPage),
        copy: markdown(entry, 'copy'),
        footer: markdown(entry, 'footer'),
        image: fluidImage(entry.image),
        image2: fluidImage(entry.image2),
      }
    : undefined;

const LAYOUT_LINK_FRAGMENT = gql`
  fragment layoutLinkFields on LayoutLink {
    sys {
      id
    }
    title
    icon {
      ...assetFields
    }
    url
    isCtaLink
    page {
      ...pageLinkFields
    }
  }
`;

const mapLayoutLink = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulLayoutLink',
        icon: mapAsset(entry.icon),
        page: mapPageLink(entry.page),
      }
    : undefined;

const LAYOUT_LINK_LIST_FRAGMENT = gql`
  fragment linkListFields on LayoutLinkList {
    title
    headline
    slug
    location
    linksCollection(limit: 10) {
      items {
        ...layoutLinkFields
      }
    }
  }
`;

const mapLayoutLinkList = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulLayoutLinkList',
        // node_locale: entry.locale,
        linksCollection: undefined,
        links: entry.linksCollection.items.filter(it => !!it).map(mapLayoutLink),
      }
    : undefined;

const WIDGET_FRAGMENT = gql`
  ${ASSET_FRAGMENT}

  fragment widgetFields on Widget {
    sys {
      id
    }
    type
    title
    description
    ctaTitle
    ctaLink
    visualStyle
    image {
      ...assetFields
    }
  }
`;

const LAYOUT_WIDGET_LIST_FRAGMENT = gql`
  ${WIDGET_FRAGMENT}
  fragment widgetListFields on LayoutWidgetList {
    sys {
      id
    }
    title
    widgetsCollection(limit: 10) {
      items {
        ...widgetFields
      }
    }
  }
`;

const mapLayoutWidgetList = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulLayoutWidgetList',
        // node_locale: entry.locale,
        widgetsCollection: undefined,
        widgets: entry.widgetsCollection.items.filter(it => !!it).map(mapWidget),
      }
    : undefined;

const LAYOUT_RICHTEXT_FRAGMENT = gql`
  fragment layoutRichTextFields on LayoutRichText {
    sys {
      id
    }
    body {
      json
      links {
        entries {
          block {
            __typename
            sys {
              id
            }
          }
        }
      }
    }
  }
`;

const mapLayoutRichRext = (entry, refMap) =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulLayoutRichText',
        body: entry.body
          ? {
              ...JSON.parse(JSON.stringify(entry.body)),
              raw: JSON.stringify(entry.body.json),
              references: refsForRichText(entry.body.links, refMap),
            }
          : undefined,
      }
    : undefined;

const PAGE_SECTION_FRAGMENT = gql`
  fragment pageSectionFields on PageSection {
    sys {
      id
    }
    title
    contentType
    columns
    imagesCollection(limit: 10) {
      items {
        ...assetFields
      }
    }
    contentCollection(limit: 10) {
      items {
        ... on LayoutHighlight {
          ...highlightFields
        }
      }
    }
  }
`;

const mapPageSection = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulPageSection',
        imagesCollection: undefined,
        images: entry.imagesCollection.items.map(img => fluidImage(img)),
        contentCollection: undefined,
        content: entry.contentCollection.items.flatMap(mapLayoutHighlight),
      }
    : undefined;

const PAGE_TABLE_FRAGMENT = gql`
  fragment pageTableFields on PageTable {
    sys {
      id
    }
    title
    body
  }
`;

const mapPageTable = entry =>
  entry
    ? {
        ...entry,
        sys: undefined,
        contentful_id: entry.sys?.id,
        __typename: 'ContentfulPageTable',
        body: { body: entry.body },
      }
    : undefined;

const COMPONENT_FRAGMENT = gql`
  fragment componentFields on Component {
    sys {
      id
    }
    template
    data
    body {
      json
    }
  }
`;

const mapWidget = entry => {
  return {
    ...entry,
    sys: undefined,
    contentful_id: entry.sys?.id,
    __typename: 'ContentfulWidget',
    description: {
      description: entry?.description || '',
    },
    ctaLink: entry.ctaLink,
    title: entry.ctaTitle,
    image: {
      ...entry.image,
      file: {
        url: entry?.image?.url,
      },
    },
  };
};

const GET_PAGE = gql`
  ${WIDGET_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${LAYOUT_LINK_FRAGMENT}
  ${LAYOUT_LINK_LIST_FRAGMENT}
  ${PAGE_LINK_FRAGMENT}

  query($id: String!, $locale: String!) {
    page(id: $id, locale: $locale, preview: true) {
      title
      slug
      excerpt
      body {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              title
              url
            }
            hyperlink {
              sys {
                id
              }
              title
              url
            }
          }
          entries {
            block {
              __typename
              sys {
                id
              }
            }
            hyperlink {
              __typename
              sys {
                id
              }
            }
            inline {
              __typename
              sys {
                id
              }
            }
          }
        }
      }
      image {
        ...assetFields
      }
      widePage
      inheritParentWidgets
      wideImage
      parentPage {
        ... on Layout {
          slug
          title
          navigation {
            ...linkListFields
          }
          hideNormalNavigation
          theme
          widgetsCollection(limit: 10) {
            items {
              ...widgetFields
            }
          }
        }
      }
      description
      keywords
      widgetsCollection(limit: 10) {
        items {
          ...widgetFields
        }
      }
    }
  }
`;

const GET_BLOGPOST = gql`
  ${ASSET_FRAGMENT}

  query($id: String!, $locale: String!) {
    blogPost(id: $id, locale: $locale, preview: true) {
      title
      body {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              title
              url
            }
            hyperlink {
              sys {
                id
              }
              title
              url
            }
          }
          entries {
            block {
              __typename
              sys {
                id
              }
            }
            hyperlink {
              __typename
              sys {
                id
              }
            }
            inline {
              __typename
              sys {
                id
              }
            }
          }
        }
      }
      image {
        ...assetFields
      }
      publishTime
      postType
      tags
      description
      keywords
      author {
        name
        jobTitle
        image {
          sys {
            id
          }
          url
          width
          height
        }
      }
    }
  }
`;

const GET_LAYOUT = gql`
  ${ASSET_FRAGMENT}
  ${PAGE_LINK_FRAGMENT}
  ${HERO_IMAGE_FRAGMENT}
  ${WIDGET_FRAGMENT}
  ${LAYOUT_HIGHLIGHT_FRAGMENT}
  ${FAQ_FRAGMENT}
  ${LAYOUT_LINK_FRAGMENT}
  ${LAYOUT_LINK_LIST_FRAGMENT}
  ${LAYOUT_WIDGET_LIST_FRAGMENT}
  ${LAYOUT_RICHTEXT_FRAGMENT}
  ${COMPONENT_FRAGMENT}

  query($id: String!, $locale: String!) {
    layout(id: $id, locale: $locale, preview: true) {
      title
      description
      keywords
      slug
      theme
      navigation {
        ...linkListFields
      }
      hideNormalNavigation
      modernLandingPage
      contentModulesCollection(limit: 10) {
        items {
          ... on Component {
            ...componentFields
          }
          ... on LayoutRichText {
            ...layoutRichTextFields
          }
          ... on Carousel {
            logoCarousel
            logoCarouselColor
            slidesCollection(limit: 10) {
              items {
                ... on LayoutHeroImage {
                  ...heroImageFields
                }
                ... on LayoutLinkList {
                  ...linkListFields
                }
              }
            }
          }
          ... on LayoutHeroImage {
            ...heroImageFields
          }
          ... on LayoutCopy {
            copy
          }
          ... on LayoutHighlight {
            ...highlightFields
          }
          ... on LayoutFaq {
            headline
            frequentlyAskedQuestionsCollection(limit: 10) {
              items {
                ...faqFields
              }
            }
          }
          ... on LayoutLink {
            ...layoutLinkFields
          }
          ... on LayoutLinkList {
            ...linkListFields
          }
          ... on LayoutWidgetList {
            ...widgetListFields
          }
          ... on Component {
            ...componentFields
          }
        }
      }
      widgetsCollection(limit: 10) {
        items {
          ...widgetFields
        }
      }
    }
  }
`;

const GET_EMBEDDED_ENTRIES = gql`
  ${ACCORDION_FRAGMENT}
  ${ASSET_FRAGMENT}
  ${BLOG_LINK_FRAGMENT}
  ${COMPONENT_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${FAQ_FRAGMENT}
  ${FAQ_CATEGORY_FRAGMENT}
  ${LAYOUT_HIGHLIGHT_FRAGMENT}
  ${LAYOUT_LINK_FRAGMENT}
  ${PAGE_LINK_FRAGMENT}
  ${PAGE_SECTION_FRAGMENT}
  ${PAGE_TABLE_FRAGMENT}
  ${SERVICE_POINT_MAP_FRAGMENT}

  query(
    $locale: String!
    $accordionIds: [String]
    $blogLinkIds: [String]
    $blogLinkLimit: Int
    $contactIds: [String]
    $contactLimit: Int
    $componentIds: [String]
    $componentLimit: Int
    $faqCategoryIds: [String]
    $servicePointMapIds: [String]
    $faqCategoryLimit: Int
    $faqIds: [String]
    $faqLimit: Int
    $highlightIds: [String]
    $highlightLimit: Int
    $layoutLinkIds: [String]
    $layoutLinkLimit: Int
    $pageLinkIds: [String]
    $pageLinkLimit: Int
    $sectionIds: [String]
    $sectionLimit: Int
    $tableIds: [String]
    $tableLimit: Int
  ) {
    accordionCollection(where: { sys: { id_in: $accordionIds } }, locale: $locale, limit: 5, preview: true) {
      items {
        ...accordionFields
      }
    }
    blogPostCollection(where: { sys: { id_in: $blogLinkIds } }, locale: $locale, limit: $blogLinkLimit, preview: true) {
      items {
        ...blogLinkFields
      }
    }
    componentCollection(
      where: { sys: { id_in: $componentIds } }
      locale: $locale
      limit: $componentLimit
      preview: true
    ) {
      items {
        ...componentFields
      }
    }
    contactCollection(where: { sys: { id_in: $contactIds } }, locale: $locale, limit: $contactLimit, preview: true) {
      items {
        ...contactFields
      }
    }
    frequentlyAskedQuestionCollection(
      where: { sys: { id_in: $faqIds } }
      locale: $locale
      limit: $faqLimit
      preview: true
    ) {
      items {
        ...faqFields
      }
    }
    frequentlyAskedQuestionCategoryCollection(
      where: { sys: { id_in: $faqCategoryIds } }
      locale: $locale
      limit: $faqCategoryLimit
      preview: true
    ) {
      items {
        ...faqCategoryFields
      }
    }
    servicePointMapCollection(where: { sys: { id_in: $servicePointMapIds } }, preview: true) {
      items {
        ...servicePointMapFields
      }
    }
    layoutHighlightCollection(
      where: { sys: { id_in: $highlightIds } }
      locale: $locale
      limit: $highlightLimit
      preview: true
    ) {
      items {
        ...highlightFields
      }
    }
    layoutLinkCollection(
      where: { sys: { id_in: $layoutLinkIds } }
      locale: $locale
      limit: $layoutLinkLimit
      preview: true
    ) {
      items {
        ...layoutLinkFields
      }
    }
    pageCollection(where: { sys: { id_in: $pageLinkIds } }, locale: $locale, limit: $pageLinkLimit, preview: true) {
      items {
        ...pageLinkFields
      }
    }
    pageSectionCollection(
      where: { sys: { id_in: $sectionIds } }
      locale: $locale
      limit: $sectionLimit
      preview: true
    ) {
      items {
        ...pageSectionFields
      }
    }
    pageTableCollection(where: { sys: { id_in: $tableIds } }, locale: $locale, limit: $tableLimit, preview: true) {
      items {
        ...pageTableFields
      }
    }
  }
`;

const GET_EMBEDDED_ENTRIES2 = gql`
  ${PAGE_LINK_FRAGMENT}
  ${LAYOUT_LINK_FRAGMENT}
  ${LAYOUT_LINK_LIST_FRAGMENT}
  ${CAROUSEL_FRAGMENT}
  ${LAYOUT_WIDGET_LIST_FRAGMENT}

  query($widgetListIds: [String], $widgetListLimit: Int, $carouselIds: [String], $carouselLimit: Int) {
    layoutWidgetListCollection(where: { sys: { id_in: $widgetListIds } }, preview: true, limit: $widgetListLimit) {
      items {
        ...widgetListFields
      }
    }
    carouselCollection(where: { sys: { id_in: $carouselIds } }, preview: true, limit: $carouselLimit) {
      items {
        ...carouselFields
      }
    }
  }
`;

const fluidImage = (image, maxWidth = 1440, quality = 60) => {
  if (!image) {
    return image;
  }

  const { url, width, height } = image;
  const aspectRatio = height ? width / height : 1.0;
  const src = `${url}?w=${maxWidth}&q=${quality}`;
  const srcWebp = `${url}?w=${maxWidth}&q=${quality}&fm=webp`;
  const sizeMultipliers = [0.25, 0.5, 1.0, 1.5, 2.0, 2.5];
  const srcSet = sizeMultipliers
    .map(m => {
      const w = Math.round(m * maxWidth);
      const h = Math.round((m * maxWidth) / aspectRatio);
      return `${url}?w=${w}&h=${h}&q=${quality} ${w}w`;
    })
    .join(',\n');
  const srcSetWebp = sizeMultipliers
    .map(m => {
      const w = Math.round(m * maxWidth);
      const h = Math.round((m * maxWidth) / aspectRatio);
      return `${url}?w=${w}&h=${h}&q=${quality}&fm=webp ${w}w`;
    })
    .join(',\n');
  const sizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`;

  return {
    contentful_id: get(image, 'sys.id'),
    title: image.title,
    description: image.description,
    fluid: {
      // base64,
      aspectRatio,
      src,
      srcSet,
      srcWebp,
      srcSetWebp,
      sizes,
    },
  };
};

const fixedImage = (image, width = 64, height = 64, cropFocus = 'face', quality = 50) => {
  if (!image) {
    return image;
  }

  const { url } = image;
  const otherProps = `q=${quality}&fit=fill&f=${cropFocus}`;
  const src = `${url}?w=${width}&h=${height}&${otherProps}`;
  const srcWebp = `${url}?w=${width}&h=${height}&${otherProps}&fm=webp`;
  const sizeMultipliers = [1.0, 1.5, 2.0, 3.0];
  const srcSet = sizeMultipliers
    .map(m => {
      const w = Math.round(m * width);
      const h = Math.round(m * height);
      return `${url}?w=${w}&h=${h}&${otherProps} ${m}x`;
    })
    .join(',\n');
  const srcSetWebp = sizeMultipliers
    .map(m => {
      const w = Math.round(m * width);
      const h = Math.round(m * height);
      return `${url}?w=${w}&h=${h}&${otherProps}&fm=webp ${w}w`;
    })
    .join(',\n');

  return {
    fixed: {
      contentful_id: get(image, 'sys.id'),
      // base64,
      width,
      height,
      src,
      srcSet,
      srcWebp,
      srcSetWebp,
    },
  };
};

const markdown = (entry, propName) => {
  const markdown = entry[propName];
  if (!markdown) return;
  return {
    [propName]: markdown,
    childMarkdownRemark: {
      html: toHtml(toHast(fromMarkdown(markdown))),
    },
  };
};

const convertPage = (page, node_locale = 'fi-FI', refMap) => {
  const contentfulPage = {
    ...page,
    __typename: 'ContentfulPage',
    node_locale,
    updatedAt: 'n/a',
    excerpt: markdown(page, 'excerpt'),
    body: page.body
      ? {
          ...JSON.parse(JSON.stringify(page.body)),
          raw: JSON.stringify(page.body.json),
          references: refsForRichText(page.body.links, refMap),
        }
      : undefined,
    image: fluidImage(page.image),
    widgetsCollection: undefined,
    widgets: page.widgetsCollection?.items?.length > 0 ? page.widgetsCollection.items.map(mapWidget) : null,
    parentPage: page.parentPage
      ? {
          ...page.parentPage,
          navigation: mapLayoutLinkList(page.parentPage.navigation),
          widgetsCollection: undefined,
          widgets:
            page.parentPage?.widgetsCollection?.length > 0
              ? page.parentPage?.widgetsCollection?.items?.map(mapWidget)
              : null,
        }
      : undefined,
  };
  console.log('page', contentfulPage);
  return { contentfulPage };
};

const convertBlogPost = (blogPost, node_locale = 'fi-FI', refMap) => {
  const contentfulBlogPost = {
    ...blogPost,
    __typename: 'ContentfulBlogPost',
    node_locale,
    updatedAt: 'n/a',
    publishTime: moment(blogPost.publishTime).format('D.M.Y'),
    body: blogPost.body
      ? {
          ...JSON.parse(JSON.stringify(blogPost.body)),
          raw: JSON.stringify(blogPost.body.json),
          references: refsForRichText(blogPost.body.links, refMap),
        }
      : undefined,
    image: fluidImage(blogPost.image),
    author: blogPost.author
      ? {
          ...blogPost.author,
          image: fixedImage(blogPost.author.image),
        }
      : null,
  };

  console.log('blogpost', contentfulBlogPost);
  return {
    contentfulBlogPost,
    allContentfulBlogPost: {
      edges: [],
    },
  };
};

const mapModules = (entry, refMap) => {
  if (entry.__typename === 'Carousel') {
    const slides = entry.logoCarousel
      ? entry.slidesCollection.items.filter(it => !!it).map(mapLayoutLinkList)
      : entry.slidesCollection.items.filter(it => !!it).map(mapHeroImage);
    return {
      ...entry,
      __typename: 'ContentfulCarousel',
      slides,
    };
  } else if (entry.__typename === 'LayoutHeroImage') {
    return mapHeroImage(entry);
  } else if (entry.__typename === 'LayoutHighlight') {
    return mapLayoutHighlight(entry);
  } else if (entry.__typename === 'LayoutFaq') {
    return {
      ...entry,
      sys: undefined,
      contentful_id: entry.sys?.id,
      __typename: 'ContentfulLayoutFaq',
      frequentlyAskedQuestionsCollection: undefined,
      frequentlyAskedQuestions: entry.frequentlyAskedQuestionsCollection.items.filter(it => !!it).map(mapFaq),
    };
  } else if (entry.__typename === 'LayoutCopy') {
    return {
      ...entry,
      sys: undefined,
      contentful_id: entry.sys?.id,
      __typename: 'ContentfulLayoutCopy',
      copy: markdown(entry, 'copy'),
    };
  } else if (entry.__typename === 'LayoutLink') {
    return mapLayoutLink(entry);
  } else if (entry.__typename === 'LayoutLinkList') {
    return mapLayoutLinkList(entry);
  } else if (entry.__typename === 'LayoutWidgetList') {
    return mapLayoutWidgetList(entry);
  } else if (entry.__typename === 'LayoutRichText') {
    return mapLayoutRichRext(entry, refMap);
  } else if (entry.__typename === 'Component') {
    return mapComponent(entry);
  }

  return {
    ...entry,
  };
};

const convertLayout = (layout, node_locale = 'fi-FI', refMap) => {
  const contentfulLayout = {
    ...layout,
    __typename: 'ContentfulLayout',
    node_locale,
    updatedAt: 'n/a',
    navigation: mapLayoutLinkList(layout.navigation),
    contentModulesCollection: undefined,
    contentModules: layout.contentModulesCollection.items.filter(it => !!it).map(it => mapModules(it, refMap)),
    widgetsCollection: undefined,
    widgets: layout.widgetsCollection.items.filter(it => !!it).map(mapWidget),
  };

  console.log('layout', contentfulLayout);
  if (layout.slug === 'home') {
    return {
      allContentfulLayout: {
        nodes: [contentfulLayout],
      },
      allContentfulBlogPost: {
        edges: [],
      },
    };
  } else {
    return {
      contentfulLayout,
    };
  }
};

const addLinks = (refMap, list) => {
  if (!Array.isArray(list)) return;
  for (const item of list) {
    const { sys: { id } = {}, ...rest } = item || {};
    if (!refMap[id]) {
      refMap[id] = { ...rest };
    }
  }
};

const addAssets = (refMap, list) => {
  if (!Array.isArray(list)) return;
  for (const item of list) {
    const { sys: { id } = {}, title, url } = item || {};
    if (!refMap[id]) {
      refMap[id] = {
        __typename: 'ContentfulAsset',
        contentful_id: id,
        title,
        file: {
          url,
        },
      };
    }
  }
};

const findRichTextLinks = (data, refMap, lvl = 0) => {
  if (typeof data !== 'object' || data === null) return;
  if (lvl === 0) {
    console.log('data:', data);
  }
  if (!data.links || !data.json) {
    for (const key of Object.keys(data)) {
      findRichTextLinks(data[key], refMap, lvl + 1);
    }
  } else {
    const { assets, entries } = data.links;
    addLinks(refMap, entries?.block);
    addLinks(refMap, entries?.hyperlink);
    addLinks(refMap, entries?.inline);
    addAssets(refMap, assets?.block);
    addAssets(refMap, assets?.hyperlink);
  }
};

const getQueryParams = (references, locale) => {
  const type2name = {
    Accordion: 'accordionIds',
    BlogPost: 'blogLinkIds',
    Component: 'componentIds',
    Contact: 'contactIds',
    FrequentlyAskedQuestion: 'faqIds',
    FrequentlyAskedQuestionCategory: 'faqCategoryIds',
    LayoutHighlight: 'highlightIds',
    LayoutLink: 'layoutLinkIds',
    Page: 'pageLinkIds',
    PageSection: 'sectionIds',
    PageTable: 'tableIds',
    ServicePointMap: 'servicePointMapIds',
    Carousel: 'carouselIds',
    LayoutWidgetList: 'widgetListIds',
  };

  const variables = { locale };
  for (const name of Object.values(type2name)) {
    variables[name] = [];
  }
  for (const [key, item] of Object.entries(references)) {
    const { __typename, ...rest } = item;
    if (Object.keys(rest).length > 0) continue;
    const idList = variables[type2name[__typename]];
    if (idList) {
      idList.push(key);
    } else if ([].includes(__typename)) {
      //
    } else {
      console.log('Unhandled type', __typename);
    }
  }

  for (const name of Object.values(type2name)) {
    variables[name.replace('Ids', 'Limit')] = variables[name].length;
  }
  return variables;
};

const addRefs = (refMap, references, list) => {
  if (!Array.isArray(list)) return;
  for (const item of list) {
    const { sys: { id } = {} } = item || {};
    if (refMap[id]) {
      references.push(refMap[id]);
    }
  }
};

const refsForRichText = (links, refMap) => {
  if (!links) return;
  const references = [];
  const { assets, entries } = links;
  addRefs(refMap, references, entries?.block);
  addRefs(refMap, references, entries?.hyperlink);
  addRefs(refMap, references, entries?.inline);
  addRefs(refMap, references, assets?.block);
  addRefs(refMap, references, assets?.hyperlink);
  return references;
};

const processReferences = (refMap, data) => {
  if (!data) return;
  const assign = (entry, value) => {
    const id = entry?.sys?.id;
    if (!id) return;
    Object.assign(refMap[id], value);
  };

  data.accordionCollection?.items?.forEach(entry => {
    assign(entry, mapAccordion(entry, refMap));
  });

  data.blogPostCollection?.items?.forEach(entry => {
    assign(entry, mapBlogLink(entry));
  });

  data.contactCollection?.items?.forEach(entry => {
    assign(entry, mapContact(entry));
  });

  data.componentCollection?.items?.forEach(entry => {
    assign(entry, mapComponent(entry));
  });

  data.frequentlyAskedQuestionCollection?.items?.forEach(entry => {
    assign(entry, mapFaq(entry));
  });

  data.frequentlyAskedQuestionCategoryCollection?.items?.forEach(entry => {
    assign(entry, mapFaqCategory(entry));
  });

  data.layoutHighlightCollection?.items?.forEach(entry => {
    assign(entry, mapLayoutHighlight(entry));
  });

  data.layoutLinkCollection?.items?.forEach(entry => {
    assign(entry, mapLayoutLink(entry));
  });

  data.pageCollection?.items?.forEach(entry => {
    assign(entry, {
      ...entry,
      __typename: 'ContentfulPage',
      contentful_id: entry.sys?.id,
      parentPage: entry.parentPage
        ? {
            ...entry.parentPage,
            sys: undefined,
            __typename: entry.parentPage.__typename === 'Page' ? 'ContentfulPage' : 'ContentfulLayout',
          }
        : undefined,
    });
  });

  data.pageSectionCollection?.items?.forEach(entry => {
    assign(entry, mapPageSection(entry));
  });

  data.pageTableCollection?.items?.forEach(entry => {
    assign(entry, mapPageTable(entry));
  });

  data.servicePointMapCollection?.items?.forEach(entry => {
    assign(entry, mapServicePointMap(entry));
  });

  data.carouselCollection?.items?.forEach(entry => {
    assign(entry, mapCarousel(entry));
  });

  data.layoutWidgetListCollection?.items.forEach(entry => {
    assign(entry, mapLayoutWidgetList(entry));
  });
};

const getPaths = (env, type, ctfId) => ({
  fi: `/preview/fi-FI/${env}/${type}/${ctfId}`,
  sv: `/preview/sv-SE/${env}/${type}/${ctfId}`,
  en: `/preview/en-US/${env}/${type}/${ctfId}`,
});

const Preview = props => {
  const { type, ctfEnv, ctfId, locale = 'fi-FI' } = props;
  const key = locale + ctfEnv + type + ctfId;
  // when path is changed, create new component
  return <PreviewKeyd key={key} {...props} />;
};

const PreviewKeyd = ({ type, ctfEnv, ctfId, locale = 'fi-FI' }) => {
  const [refMap] = useState({
    [ctfId]: {
      __typename: capitalize(type),
      root: true,
    },
  });
  const [unfetchedEntries, setUnfetched] = useState(getQueryParams({}, locale));
  const [errorList, setErrors] = useState([]);

  const { loading, error, data } = useQuery(
    type === 'blog' ? GET_BLOGPOST : type === 'layout' ? GET_LAYOUT : GET_PAGE,
    {
      variables: { id: ctfId, locale },
    }
  );

  useEffect(() => {
    if (error) {
      setErrors(l => [...l, error]);
    }
  }, [error, setErrors]);

  useEffect(() => {
    console.log('data1', data);
    findRichTextLinks(data, refMap);
    const unfetched = getQueryParams(refMap, locale);
    setUnfetched(unfetched);
  }, [data, refMap, locale]);

  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_EMBEDDED_ENTRIES, {
    variables: unfetchedEntries,
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (error2) {
      setErrors(l => [...l, error2]);
    }
  }, [error2, setErrors]);

  useEffect(() => {
    if (loading2) return;
    findRichTextLinks(data2, refMap);
    processReferences(refMap, data2);
    const unfetched = getQueryParams(refMap, locale);
    setUnfetched(unfetched);
  }, [loading2, data2, refMap, locale]);

  const { loading: loading3, error: error3, data: data3 } = useQuery(GET_EMBEDDED_ENTRIES2, {
    variables: unfetchedEntries,
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (error3) {
      setErrors(l => [...l, error3]);
    }
  }, [error3, setErrors]);

  useEffect(() => {
    if (loading3 || !data) return;
    findRichTextLinks(data3, refMap);
    processReferences(refMap, data3);
    const unfetched = getQueryParams(refMap, locale);
    setUnfetched(unfetched);
  }, [loading3, data3, refMap, locale]);

  if (loading || loading2 || loading3) {
    return <div>Lataa</div>;
  }

  if (error || error2 || error3) {
    return <pre>Virhe {JSON.stringify([error, error2, error3], null, 2)}</pre>;
  }

  const combinedData = {
    ...data,
    embeddedEntries: data2,
    embeddedEntries2: data3,
  };

  const pageContext = { paths: getPaths(ctfEnv, type, ctfId) };

  return (
    <>
      {type === 'blog' ? (
        <BlogPost data={convertBlogPost(combinedData.blogPost, locale, refMap)} pageContext={pageContext} />
      ) : type === 'layout' ? (
        combinedData.layout.slug === 'home' ? (
          <HomePage data={convertLayout(combinedData.layout, locale, refMap)} pageContext={pageContext} />
        ) : (
          <Layout data={convertLayout(combinedData.layout, locale, refMap)} pageContext={pageContext} />
        )
      ) : (
        <Page data={convertPage(combinedData.page, locale, refMap)} pageContext={pageContext} />
      )}
      {errorList.length > 0 && <pre>{JSON.stringify(errorList, null, 2)}</pre>}
    </>
  );
};

const ContentfulClient = ({ ctfEnv, children }) => {
  const client = useMemo(() => {
    const space = process.env.CONTENTFUL_SPACE_ID;
    const token = process.env.CONTENTFUL_PREVIEW_TOKEN;

    const httpLink = createHttpLink({
      uri: `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${ctfEnv}`,
    });
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }));

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [ctfEnv]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default () => {
  analytics.usePageCategory('not_set');

  return (
    <Router basepath="/preview">
      <ContentfulClient path="/:locale/:ctfEnv">
        <Preview path="/page/:ctfId" type="page" />
        <Preview path="/blog/:ctfId" type="blog" />
        <Preview path="/layout/:ctfId" type="layout" />
      </ContentfulClient>
    </Router>
  );
};
