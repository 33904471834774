/** @jsx jsx */
import { jsx, Grid, Flex, Box, Container, Button, useThemeUI } from 'theme-ui';
import React, { useEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import { Hero, Layout } from '../components';
import { Copy, LinkList, Highlight, FAQ, WidgetList } from '../components/content-modules';
import { ContentfulWidgets, WidgetContainer } from '../components/widgets';
import SmartAppBanner from '../components/SmartAppBanner';
import { useSelector, useDispatch } from 'react-redux';
import { changeLocale } from '../state/session';
import RichText from '../components/RichText';
import Carousel from '../components/Carousel';
import Link from '../components/Link';
import PageNavigation from '../components/PageNavigation';
import * as analytics from '../utils/analytics';
import PageHero from '../components/PageHero';
import Accordion from '../components/Accordion';
import PageGallery from '../components/PageGallery';
import VideoGallery from '../components/VideoGallery';
import LogoCarousel from '../components/LogoCarousel';
import ContentfulComponent from '../components/ContentfulComponent';

const WidgetWrapper = props => (
  <Box sx={{ mt: props.colorMode == 'dark' ? [0, 3, 4] : 0, mx: [-3, 0] }}>
    <Grid
      sx={{
        gridTemplateColumns: [null, null, '1fr 1fr', '2fr 1fr 1fr', 'minmax(800px, auto) 1fr 1fr'],
        gridTemplateRows: [null, 'auto auto', null, null, 'auto auto'],
        gridGap: [1, null, 2, null, 3],
        '> :first-child': {
          gridColumn: [1, null, '1 / span 3', null, 1],
          gridRow: [null, null, null, null, '1 / 3'],
        },
        '> :nth-child(2)': {
          gridColumn: [null, null, '1 / span 3', 1, '2 / span 2'],
        },
      }}
    >
      {props.children}
    </Grid>
  </Box>
);

const LandingPage = ({ data, pageContext }) => {
  const {
    title,
    node_locale,
    description,
    keywords,
    updatedAt,
    slug,
    navigation,
    hideNormalNavigation,
    widgets,
    hideLocales,
    modernLandingPage,
  } = data.contentfulLayout;
  let { theme } = data.contentfulLayout;
  analytics.usePageCategory(slug || 'not_set');

  if (!theme) {
    const path = pageContext.paht || (typeof window !== 'undefined' ? window.location.pathname : '');
    const path1 = path.split('/')[1];
    if (
      ['yrityksille', 'foretagskunder', 'corporate-customers', 'yrityksille-jani-test'].includes(path1) ||
      modernLandingPage
    ) {
      theme = 'dark';
    } else if (['vastuullisuus', 'ansvarighet', 'sustainability'].includes(path1)) {
      theme = 'green';
    }
  }

  const locale = useSelector(state => state.session.locale);
  const dispatch = useDispatch();
  useEffect(() => {
    const pageLocale = node_locale && node_locale.includes('-') && node_locale.substring(0, node_locale.indexOf('-'));
    if (pageLocale && pageLocale !== locale) {
      dispatch(changeLocale(pageLocale));
    }
  }, []);

  let contentModules = [];
  let reverseHighlight = !!modernLandingPage;
  const { colorMode } = useThemeUI();

  let modules = [...data.contentfulLayout.contentModules];
  let hero = null;
  if (['ContentfulCarousel', 'ContentfulLayoutHeroImage'].includes(modules[0]?.__typename)) {
    hero = modules.shift();
  }

  modules.map((mod, i) => {
    switch (mod.__typename) {
      case 'ContentfulCarousel':
        if (mod?.logoCarousel) {
          contentModules.push(
            <Box>
              <LogoCarousel slides={mod.slides} color={mod.logoCarouselColor} />
            </Box>
          );
        } else {
          contentModules.push(
            <WidgetWrapper colorMode={colorMode} key={i}>
              <Carousel slides={mod.slides} />
              {i === 0 && widgets && <ContentfulWidgets widgets={widgets} />}
            </WidgetWrapper>
          );
        }

        break;
      case 'ContentfulLayoutHeroImage':
        const hero = widgets ? (
          <WidgetWrapper colorMode={colorMode} key={i}>
            <Box sx={{ borderRadius: [0, 2], overflow: 'hidden' }}>
              <Hero
                style={mod.visualStyle}
                backgroundImage={mod.backgroundImage}
                backgroundColor={mod.backgroundColor}
                accentColor={mod.accentColor}
                title={mod.headline}
                theme={theme}
              />
            </Box>
            {i === 0 && widgets && <ContentfulWidgets widgets={widgets} />}
          </WidgetWrapper>
        ) : (
          <Box
            sx={{
              mx: [-3, null, null, -4, null, 0],
              borderRadius: [0, 0, 0, 0, 0, 0],
              overflow: 'hidden',
            }}
          >
            <Hero
              style={mod.visualStyle}
              backgroundImage={mod.backgroundImage}
              backgroundColor={mod.backgroundColor}
              accentColor={mod.accentColor}
              title={mod.headline}
              theme={theme}
            />
            {!!navigation && <PageNavigation navigation={navigation} theme={theme} />}
          </Box>
        );
        contentModules.push(hero);
        break;
      case 'ContentfulFrequentlyAskedQuestionCategory':
        const { headline, frequentlyAskedQuestions } = mod;
        let faq = [];
        frequentlyAskedQuestions.forEach(q => {
          const { question, answer, contentful_id } = q;
          faq.push(
            <Accordion variant="faq" title={question} id={contentful_id}>
              <div
                dangerouslySetInnerHTML={{
                  __html: answer.childMarkdownRemark.html,
                }}
              />
            </Accordion>
          );
        });

        contentModules.push(<Box sx={{ mb: 3 }}>{faq}</Box>);
        break;
      case 'ContentfulLayoutHighlight':
        if (i > 0 && modules[i - 1].__typename === 'ContentfulLayoutHighlight') reverseHighlight = !reverseHighlight;
        contentModules.push(
          <Highlight
            content={mod}
            theme={theme === 'green' ? 'greenDark' : theme === 'dark' ? 'primary' : null}
            modern={modernLandingPage}
            reverse={reverseHighlight}
            backgroundColor={mod.backgroundColor}
            key={i}
          />
        );
        break;
      case 'ContentfulLayoutCopy':
        contentModules.push(<Copy content={mod.copy} key={i} />);
        break;
      case 'ContentfulComponent':
        const { template, body } = mod;
        const dataNotRequiredTemplates = [
          'Notification',
          'ParcelWidget',
          'ParcelTracking',
          'MultiParcelWidget',
          'TicketWidget',
        ];
        if (!dataNotRequiredTemplates.includes(template)) {
          break;
        }

        contentModules.push(
          <ContentfulComponent template={template} locale={locale} body={body} sxx={{ mt: i === 0 ? 4 : 0, mb: 0 }} />
        );
        break;
      case 'ContentfulLayoutLink':
        const link = mod;
        if (link.isCtaLink) {
          contentModules.push(
            <Box>
              <Button as={Link} to={{ link: link.url, external: true }}>
                {link.title}
              </Button>
            </Box>
          );
        } else {
          contentModules.push(
            <Flex
              sx={{
                justifyContent: 'center',
              }}
            >
              <Button
                as={Link}
                to={
                  link.page && link.page.parentPage
                    ? `/${link.page.parentPage.slug}/${link.page.slug}`
                    : link.page
                    ? `/${link.page.slug}`
                    : link.url
                }
                noTitleFix
                key={link.id}
                sx={{
                  bg: theme === 'green' ? 'greenDark' : theme === 'dark' ? 'primary' : null,
                }}
              >
                {link.title}
              </Button>
            </Flex>
          );
        }
        break;
      case 'ContentfulLayoutLinkList':
        contentModules.push(<LinkList links={mod.links} key={i} />);
        break;

      case 'ContentfulPageSection':
        if (mod.contentType === 'Image') {
          contentModules.push(<PageGallery title={mod.title} images={mod.images} />);
        }
        if (mod.contentType === 'Video') {
          contentModules.push(<VideoGallery title={mod.title} videos={mod.images} />);
        }
        if (mod.contentType === 'Highlight') {
          let highlights = mod.content.map(c => {
            return <Highlight content={c} key={c.contentful_id} backgroundColor={c.backgroundColor} card />;
          });
          return contentModules.push(
            <Grid
              sx={{
                gridGap: [3, null, null, 4],
                gridTemplateColumns: ['1fr', null, `repeat(${highlights.length}, 1fr)`],
                gridTemplateRows: ['auto', null, '1fr'],
                my: [4, 5],
                ml: 0,
                mr: highlights?.length >= 3 ? 0 : [0, null, null, -4, -6],
              }}
            >
              {highlights}
            </Grid>
          );
        }
        break;
      case 'ContentfulLayoutWidgetList':
        contentModules.push(<WidgetList widgets={mod.widgets} key={i} />);
        break;
      case 'ContentfulLayoutFaq':
        contentModules.push(<FAQ content={mod} key={i} />);
        break;
      case 'ContentfulLayoutRichText':
        contentModules.push(
          <Box>
            <RichText content={mod.body} locale={locale} key={i} assets={pageContext.assetsInBody} />
          </Box>
        );
        break;
      default:
        break;
    }
  });

  return (
    <Layout
      title={title}
      showAppBanner={true}
      path={pageContext.paht}
      paths={pageContext.paths}
      description={description}
      keywords={keywords}
      locale={node_locale}
      updatedAt={updatedAt}
      showNavigation={navigation ? navigation.location !== 'belowHero' : true}
      navigation={navigation}
      hideNormalNavigation={hideNormalNavigation}
      hideLocales={hideLocales || []}
      parentSlug={slug}
      theme={theme}
    >
      {hero && hero.__typename === 'ContentfulCarousel' && (
        <Carousel
          theme={theme}
          slides={hero.slides}
          sx={{
            mb: [1, 3, 4],
          }}
        />
      )}
      {hero && hero.__typename === 'ContentfulLayoutHeroImage' && (
        <Box
          sx={{
            mx: [null, null, null, null, null, 0],
            mb: [1, 3, 4],
            borderRadius: [0, 0, 0, 0, 0, 0],
            overflow: 'hidden',
          }}
        >
          <Hero
            style={hero.visualStyle}
            backgroundImage={hero.backgroundImage}
            backgroundColor={hero.backgroundColor}
            accentColor={hero.accentColor}
            title={hero.headline}
            theme={theme}
          />
          {!!navigation && navigation.location === 'belowHero' && (
            <PageNavigation navigation={navigation} theme={theme} hideWhenNarrow={hideNormalNavigation} />
          )}
        </Box>
      )}
      {modernLandingPage && <PageHero title={title} excerpt={false} theme={'dark'} />}
      <Container>
        <Grid gap={[4, 5]} mb={[4, 5]} lang={locale || 'fi'}>
          {contentModules && contentModules}
        </Grid>
      </Container>
    </Layout>
  );
};

export const landingPageQuery = graphql`
  query($id: String!) {
    contentfulLayout(id: { eq: $id }) {
      id
      title
      node_locale
      description
      keywords
      updatedAt
      slug
      theme
      navigation {
        ...navigationFields
      }
      hideNormalNavigation
      modernLandingPage
      hideLocales
      contentModules {
        ... on ContentfulLayoutRichText {
          contentful_id
          body {
            raw
            references {
              ... on ContentfulAccordion {
                ...accordionFields
              }
              ... on ContentfulPageSection {
                ...pageSectionFields
              }
              ... on ContentfulAsset {
                ...assetFields
              }
              ... on ContentfulLayoutHighlight {
                ...highlightFields
              }
              ... on ContentfulComponent {
                ...componentFields
              }
              ... on ContentfulLayoutWidgetList {
                ...widgetListFields
              }
            }
          }
        }
        ... on ContentfulCarousel {
          ...carouselFields
        }

        ... on ContentfulComponent {
          ...componentFields
        }

        ... on ContentfulLayoutHeroImage {
          id
          headline
          visualStyle
          backgroundImage {
            fluid(maxWidth: 1280, quality: 60) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          callToAction {
            title
            url
            page {
              ...pageLinkFields
            }
          }
          backgroundColor
          accentColor
          languages
        }
        ... on ContentfulPageSection {
          ...pageSectionFields
        }
        ... on ContentfulLayoutCopy {
          copy {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulLayoutLinkList {
          title
          links {
            title
            icon {
              file {
                url
                fileName
                contentType
                details {
                  image {
                    width
                    height
                  }
                }
              }
            }
            page {
              ...pageLinkFields
            }
          }
        }

        ... on ContentfulFrequentlyAskedQuestion {
          ...faqFields
        }
        ... on ContentfulFrequentlyAskedQuestionCategory {
          ...faqCategoryFields
        }

        ... on ContentfulLayoutWidgetList {
          widgets {
            contentful_id
            type
            title
            description {
              description
            }
            ctaTitle
            ctaLink
            visualStyle
          }
        }
        ... on ContentfulLayoutLink {
          title
          url
          isCtaLink
          page {
            ...pageLinkFields
          }
        }
        ... on ContentfulLayoutHighlight {
          headline
          copy {
            childMarkdownRemark {
              html
            }
          }
          ctaLink
          ctaTitle
          visualStyle
          backgroundColor
          image {
            fluid(maxWidth: 720, quality: 60) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          image2 {
            fluid(maxWidth: 720, quality: 60) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          ctaPage {
            ...pageLinkFields
          }
          footer {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulLayoutFaq {
          headline
          frequentlyAskedQuestions {
            question
            answer {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      widgets {
        contentful_id
        type
        title
        description {
          description
        }
        ctaTitle
        ctaLink
        visualStyle
      }
    }
  }
`;

export default LandingPage;
